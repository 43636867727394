import React, { useEffect } from 'react';
import Header from './components/header/Header';
import { fetchCountries } from './redux/slices/countries';
import { useAppDispatch, useAppSelector } from './redux/hooks';
import Main from './screens/main/Main';

function App() {
  const dispatch = useAppDispatch()
  const { loading, error } = useAppSelector(state => state.countries)

  useEffect(() => {
    dispatch(fetchCountries())
  }, [dispatch])

  if (error) return <div>{error}</div>

  return (
    <div className='container'>
      <Header />
      {loading ? (
        <div>loading</div>
      ) : (
        <Main />
      )}
    </div>
  );
}

export default App;
