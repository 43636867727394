import React, { useState } from 'react'
import { motion } from 'framer-motion'

import styles from './styles/header.module.css'
import { ReactComponent as Logo } from '../../images/logo.svg'
import { ReactComponent as MenuIcon } from '../../images/bars-solid.svg'
import { ReactComponent as CloseIcon } from '../../images/circle-xmark-regular.svg'

import { allRegions } from './map/regions'

const Header = () => {
  const [showMenu, setShowMenu] = useState(false);

  const handleClick = () => {
    setShowMenu((prev) => !prev)
  }

  const animateFrom = { opacity: 0, y: -40 }
  const animateTo = { opacity: 1, y: 0 }

  const RegionsMap = () => {
    return (
      <>
        {allRegions.map((region, index) => (
          <motion.li
            key={index}
            initial={animateFrom}
            animate={animateTo}
            transition={{ delay: index * 0.05 }}
            className={styles.header_region}>
            {region}
          </motion.li>
        ))}
      </>
    )
  }

  return (
    <header className={styles.header_container}>
      <div className={styles.header_left_wrapper}>
        <div className={styles.header_logo_wrapper}>
          <Logo />
          <h1 className={styles.logo_text}>
            PlayWallet<span>.Status</span>
          </h1>
        </div>
        <p className={styles.header_left_text}>
          This site shows all of the Steam countries available for recharging via Play Wallet
        </p>
      </div>
      <ul className={styles.header_regions}>
        <RegionsMap />
      </ul>
      <div className={styles.mobile_menu}>
        {showMenu ? (
          <CloseIcon
            fill='white'
            width='3.5rem'
            height='3.5rem'
            cursor='pointer'
            onClick={() => handleClick()} />
        ) : (
          <MenuIcon
            fill='white'
            width='3rem'
            height='3rem'
            cursor='pointer'
            onClick={() => handleClick()} />
        )}
        {showMenu && (
          <div className={styles.mobile_menu_container}>
            <ul className={styles.mobile_header_regions}>
              <RegionsMap />
            </ul>
          </div>
        )}
      </div>
    </header>
  )
}

export default Header