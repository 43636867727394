import React from 'react'

import styles from '../main/styles/main.module.css'
import CisRegion from './components/CisRegion'
import AmericasRegion from './components/AmericasRegion'
import EuropeRegion from './components/EuropeRegion'
import AsiaRegion from './components/AsiaRegion'
import ArabRegion from './components/ArabRegion'
import OceaniaRegion from './components/OceaniaRegion'
import AfricaRegion from './components/AfricaRegion'

const Main = () => {
  return (
    <div className={styles.main_container}>
      <CisRegion />
      <AmericasRegion />
      <EuropeRegion />
      <AsiaRegion />
      <ArabRegion />
      <OceaniaRegion />
      <AfricaRegion />
    </div>
  )
}

export default Main