import React, { useMemo, useCallback } from 'react';
import styles from '../styles/main.module.css';
import { useAppSelector } from '../../../redux/hooks';
import { Country } from '../../../redux/types';

const CisRegion = () => {
  const { countries } = useAppSelector((state) => state.countries);

  const filterCisCountries = useCallback((countries: Country[]) => {
    return countries.filter((country) => country.region === 'CIS');
  }, []);

  const sortedCisCountries = useMemo(() => {
    const cisCountries = filterCisCountries(countries);
    return cisCountries.sort((a, b) => a.name.localeCompare(b.name));
  }, [countries, filterCisCountries]);

  return (
    <div className={styles.region_container}>
      <table>
        <thead>
          <tr>
            <th>CIS Region</th>
            <th>Value</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {sortedCisCountries.map((country) => (
            <tr key={country.name}>
              <td><img style={{ paddingRight: '0.5rem' }} src={`${process.env.REACT_APP_DOMAIN}${country.flag}`} alt='flag' />
                {country.name}</td>
              <td>{country.value}</td>
              {country.status === 'Active' ? (
                <td style={{ color: '#5CBE6B' }}>{country.status}</td>
              ) : (country.status === 'No info' ? (
                <td style={{ color: '#75B4EF' }}>{country.status}</td>
              ) : (
                <td style={{ color: '#E34F4F' }}>{country.status}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CisRegion;
