import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import type { Country, CountryState } from '../types';

export const fetchCountries = createAsyncThunk<Country[]>(
  'countries/fetchCountries',
  async function () {
    const res = await fetch(process.env.REACT_APP_DOMAIN+'/api/countries?fields[0]=name&fields[1]=value&fields[2]=status&populate[flag][fields][0]=url&populate[region][fields][0]=name')

    if (!res.ok) {
      throw new Error('Server error!')
    }

    const data = await res.json()

    const filteredData: Country[] = data.data.map((country: any) => ({
      name: country.attributes.name,
      value: country.attributes.value,
      status: country.attributes.status,
      flag: country.attributes.flag.data.attributes.url,
      region: country.attributes.region.data.attributes.name
    }))

    return filteredData
  }
)

const initialState: CountryState = {
  countries: [],
  loading: false,
  error: null
}

const countries = createSlice({
  name: 'countries',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCountries.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCountries.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.countries = action.payload
      })
      .addCase(fetchCountries.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message!;
      })
  }
});

export default countries.reducer