import { configureStore } from '@reduxjs/toolkit'
import countries from './slices/countries'

export const store = configureStore({
  reducer: {
    countries: countries
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch